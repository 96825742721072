const postData = async (url, data) => {
    return $.ajax({
        url: url,
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        data: data,
        dataType: 'text'
    });    
};


export {postData};