const circlesMain = () => {
    $(".ui-block-04.stats-sec .circular-wrap").appear(function () {
        $(".ui-block-04.stats-sec .circle").circleProgress({
            size: 210,
            lineCap: "round",
            fill: {
                gradient: ["#ffffff", "#ffffff"],
            },
        });
        $("#ui-block-04-circle-two").circleProgress({
            size: 210,
            lineCap: "round",
            fill: {
                gradient: ["#ea0026", "#ea0026"],
            },
        });
    });
    $(".ui-block-04.stats-sec .circular-wrap.dark").appear(function () {
        $('.ui-block-04.stats-sec .myskill').circleProgress({
            lineCap: "round",
            size: 200,
        });
    });
    

}

const circlesSitesPage = () => {
    $(".ui-block-16.stats-sec .circular-wrap").appear(function () {
        $(".ui-block-16.stats-sec .circle").circleProgress({
            size: 210,
            lineCap: "round",
            fill: {
                gradient: ["#202020", "#202020"]
            },
        });
    });
    $(".ui-block-16.stats-sec .circular-wrap.dark").appear(function () {
        $('.ui-block-16.stats-sec .myskill').circleProgress({
            lineCap: "round",
            size: 200,
        });
    });
    

}


export {circlesMain, circlesSitesPage};