'use strict';

import { preloader } from "./modules/preloader";
import { homePageHeight } from "./modules/homePageHeight";
import { printText } from "./modules/printText";
import { up } from "./modules/up";
import { fullmenu } from "./modules/menu";
import { circlesMain, circlesSitesPage } from "./modules/circles";
import { portfolio } from "./modules/portfolio";
import { initOwlCarousel } from "./modules/initOwlCarousel";
import { order } from "./modules/order";
import { checkNumInputs } from "./services/checkNumInputs";
/* import { language } from "./modules/language"; */
/* import { cookies } from "./modules/cookies"; */


const isMain = $('body.page-main').length > 0;
const isPageSite = $('body.page-site').length > 0;
/* const isPolicy = $('body.page-policy').length > 0; */


$(document).ready( function() {    
    preloader();
    homePageHeight();
    printText();
    up(1000, 700);//нижче 1000 пікселів, 0,7 секунд плавність
    fullmenu();

    if (isMain) {
        circlesMain();
    }

    if (isPageSite) {
        circlesSitesPage();
    }

    portfolio();
    initOwlCarousel();
	new WOW().init();
	
	order();
	checkNumInputs('input[name="phone"]');

	/* language(); */

	/* if (!isPolicy) {
		cookies();
	} */	
	


    // MAGNIFIC POPUP FOR PORTFOLIO PAGE
    // $('.magnif').magnificPopup({
    //     type:'image',
    //     gallery:{enabled:true},
    //     zoom:{enabled: true, duration: 300}
    // });


});