/* import { calcScroll } from "../services/calcScroll"; */

// RESPONSIVE MENU
const fullmenu = () => {
    //відкриття/закриття меню
    $('.nav-icon').click(function(){
        $('body').toggleClass('full-open');
    });
    

    $('.scroll').click(function() {  // закриває меню при скролі до потрібного елемента
        $('body').removeClass('full-open');
    });
    

}


export {fullmenu};