import { postData } from "../services/requests";
import { formValidate } from "../services/formValidate";
import { calcScroll } from "../services/calcScroll";

const order = () => {
    const form = $('form');
    const scroll = calcScroll(); 
    
    const messageLanguage = {        
        uk: {
            loading: {
                color: '#fae100',
                title: 'Йде відправка...',
                subtitle: 'Будь-ласка, зачекайте зміни статусу відправки повідомлення'
            },
            success: {
                color: '#57cc09',
                title: 'Дякую за заявку!',
                subtitle: 'Я невдовзі зв\'яжусь з Вами.'
            },
            failure: {
                color: '#f14216',
                title: 'Щось пішло не так...',
                subtitle: 'Виникла помилка при відправці форми. Будь-ласка, повторіть спробу пізніше.'
            }  
        }         
    };

    $(form).submit(function(e) {
        e.preventDefault();        
        
        let error = formValidate(this);        


        /* let language = localStorage.getItem('language');
        if (!language) {
            language = 'uk';
        }        
        let message = messageLanguage[language]; */

        let message = messageLanguage['uk'];
        
        if (error === 0) {
            thanksModalInit();
            textThanksModal(message.loading);
            const formData = new FormData(this);
            
            const jsonData = Object.fromEntries(formData.entries());
            /* console.log(jsonData); */
            
            /* jsonData.language = language; */
            
            if (jsonData.data) {
                changeDataView(jsonData); //форматування вигляду дати
            }        
            jsonData.pageInfo = window.location.href;
            recordDate(jsonData); //додаємо рядок з датою заявки 
            
            const jsonString = JSON.stringify(jsonData);
            //console.log(jsonString);
            


            postData('smart.php', jsonString)
                .then(res => {                     
                    //console.log(res);
                    dataLayer.push({
                        'event': 'formSubmission', // Подія відправки форми (Треба для Google Tag Manager)
                    });
                    textThanksModal(message.success);          
                    $(form).trigger('reset');
                })
                .catch(error => {
                    console.log(error);
                    textThanksModal(message.failure);
                });
        } else {
            //якщо є незаповнені обов'язкові поля, то скролить до найпершого поля з помилкою
            let firstErrorElement = $(this).find('._error').first();
            if (firstErrorElement.length) {
                $('html, body').animate({
                    scrollTop: firstErrorElement.offset().top - 50 // Відкоригувати відступ
                }, 500);
            }
        }
        
    });

    function thanksModalInit() {
        $('body').css({
            'overflow': 'hidden',
            'margin-right': `${scroll}px`
        });
        $('.thanks').css('display', 'flex');
        $('.thanks').addClass('fadeIn');
        $('.thanks__close').on('click', () => {
            closeThanks();
        });
        $('.thanks').on('click', (e) => {
            if($(e.target).is('.thanks')) {
                closeThanks();
            }
        });        
    }

    function  textThanksModal(message) {
        $('.thanks').css('border-color', message.color);
        $('.thanks').css('box-shadow', `inset 0px 2px 8px ${message.color}`);
        $('.thanks__title').text(message.title);
        $('.thanks__subtitle').text(message.subtitle);
        $('.thanks__line').css('background-color', message.color);
        $('.thanks__modal').css('box-shadow', `0px 2px 8px ${message.color}`);
        setTimeout(() => {
            closeThanks();
        }, 10000);
    }

    function closeThanks() {            
        /* $('.thanks').addClass('fadeOut'); */
        $('.thanks').fadeOut();
        $('body').css({
            'overflow': '',
            'margin-right': '0'
        });
        setTimeout(() => {            
            $('.thanks').css('display', 'none');
            /* $('.thanks').removeClass('fadeOut'); */
        }, 700);
    }

    function changeDataView(object) {
        const rawDate = $("#formDate").val(); // Отримуємо рядкове значення з інпуту з датою
        const dateParts = rawDate.split("-"); // Розбиваємо рядок на частини
        const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`; // Форматуємо дату
        object.date = formattedDate; // Замінюємо значення в об'єкті
    }


    function recordDate(object) {
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();
    
        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${hours}:${minutes}:${seconds}`;
    
        object.dateTime = `${formattedDate} ${formattedTime}`;
    }
    
}

export {order};