/*===================================
                  fixed bottom arrow
    ======================================*/

const up = (scrollTop, smoothScroll) => {    

    $(window).on('scroll', function () {
        if ($(this).scrollTop() > scrollTop) {
            $('.bottom-arr').addClass('fixed-at-bottom');
        } else {
            $('.bottom-arr').removeClass('fixed-at-bottom');
        }
    });

    //Click event to scroll to top
    $(document).on('click', '.scroll-top-arrow', function () {
        $('html, body').animate({scrollTop: 0}, smoothScroll);
        return false;
    });
}

export {up};