const preloader = () => {
    // HOME PAGE HEIGHT
    jQuery(window).load(function() {
        // will first fade out the loading animation
        jQuery(".loader").fadeOut();
        // will fade out the whole DIV that covers the website.
        jQuery(".preloader").delay(1000).fadeOut("slow");
    });

}


export {preloader};