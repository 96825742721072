const homePageHeight = () => {    
    // HOME PAGE HEIGHT 
    //розраховує висоту для першого екрану головної сторінки
    if ($('.home, .portfolio-hero').length) {
        function fullhome() {
            var hometext = $('.home, .portfolio-hero')            
        //  var homett = $('.hero-title').offset();
        //  $('.social').css('margin-top', homett.top)            
            hometext.css({
                "height": $(window).height() + "px"
            });
        }
        fullhome();
        $(window).resize(fullhome);
    }
    
}

export {homePageHeight};